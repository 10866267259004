<template>
  <div :id="el"></div>
</template>

<script>
import { Player, Events } from "clappr";
//import LevelSelector from "level-selector";
export default {
  name: "VideoPlayer",
  props: {
    //accessToken: {
    //  type: String,
    //},
    el: {
      type: String,
      required: true,
      default: "player"
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        poster: "",
        mute: false,
        autoplay: false,
        loop: false,
        disable_context_menu: false,
        disable_keyboard_shortcuts: false,
        mediacontrol: {},
        watermark: {}
      })
    }
  },
  data: () => ({
    player: null,
  }),
  beforeDestroy() {
    this.player.destroy();
    this.player = null;
  },
  methods: {
    init(seektime) {
      const player = this.initClapprPlayer();
      this.player = player;
      this.registerEvents(player);
      this.player.seek(seektime)
    },
    initClapprPlayer() {
      const player = new Player({
        parentId: `#${this.el}`,
        source: this.options.source,
        autoPlay: this.options.autoplay,
        poster: this.options.poster,
        mute: this.options.mute,
        loop: this.options.loop,
        mediacontrol: this.options.mediacontrol,
        disableKeyboardShortcuts: this.options.disable_keyboard_shortcuts,
        disableVideoTagContextMenu: this.options.disable_context_menu,
        watermark: this.options.watermark.image,
        watermarkLink: this.options.watermark.url,
        position: this.options.watermark.position,
        width: this.options.width,
        height: this.options.height,
        hlsjsConfig: this.options.hlsjsConfig
      });
      this.$emit("init", player);
      return player;
    },
    registerEvents(player) {
      player.on(Events.PLAYER_READY, () => {
        this.$emit("ready", player);
      });
      player.on(Events.PLAYER_PLAY, () => {
        this.$emit("play", player);
      });
      player.on(Events.PLAYER_PAUSE, () => {
        this.$emit("pause", player);
      });
      player.on(Events.PLAYER_STOP, () => {
        this.$emit("stop", player);
      });
      player.on(Events.PLAYER_ENDED, () => {
        this.$emit("ended", player);
      });
      player.on(Events.PLAYER_FULLSCREEN, bool => {
        this.$emit("fullscreen", bool);
      });
      player.on(Events.PLAYER_RESIZE, resize => {
        this.$emit("resize", resize);
      });
      player.on(Events.PLAYER_SEEK, time => {
        this.$emit("seek", time);
      });
      player.on(Events.PLAYER_TIMEUPDATE, progress => {
        this.$emit("timeupdate", progress);
      });
      player.on(Events.PLAYER_VOLUMEUPDATE, volume => {
        this.$emit("volumeupdate", volume);
      });
      player.on(Events.PLAYER_ERROR, err => {
        this.$emit("error", err);
      });
    }
  }
};
</script>

<style scoped>
#player {
  box-shadow: 0 0 20px gray;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#player >>> .play-wrapper[data-poster] svg path {
    fill: #E0E0E0;
}

#player >>> .player-poster[data-poster] {
  background-size: contain;
}
</style>