<template>
  <div class="-trend">
    <div class="c_main -pad-btm">
      <div class="inner">
        <nav class="c_pnkz">
          <ul class="c_pnkz_list">
            <li>
              <a class="c_pointer" @click="$router.push({ name: 'Home' })"
                >ホーム</a
              >
            </li>
            <li>
              <a
                class="c_pointer"
                @click="$router.push({ name: routerName })"
                >{{ category }}</a
              >
            </li>
            <li>{{ contents.name }}</li>
          </ul>
        </nav>
        <p class="c_main_ttl">{{ category }}</p>

        <video-single
          ref="video"
          :accessToken="accessToken"
          :contents="contents"
        ></video-single>

        <dl class="c_eval">
          <dt v-if="!evaluated">この動画を評価する</dt>
          <dt v-else>評価済み</dt>
          <dd>
            <div id="star" class="c_eval_star">
              <star-rating
                v-model="contents.my_evaluation"
                :width="20"
                :height="20"
              ></star-rating>
            </div>
            <button
              v-if="!evaluated"
              class="c_btn c_eval_submit"
              type="button"
              @click="evaluate"
            >
              評価投稿
            </button>
            <button
              v-else
              class="c_txtlink -arrow sec_item_eval_update"
              @click="evaluate"
            >
              評価を変更する
            </button>
          </dd>
        </dl>
        <div class="c_pageback">
          <a class="c_txtlink" @click="$router.push({ name: routerName })"
            >一覧に戻る</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoSingle from "@/components/VideoSingle";
import StarRating from "@/components/StarRating";

export default {
  components: {
    VideoSingle,
    StarRating,
  },
  props: {
    id: {
      type: String,
    },
    category: {
      type: String,
    },
    routerName: {
      type: String,
    },
  },
  data() {
    return {
      accessToken: "",
      contents: {},
      evaluated: false,
      video_inited: false,
    };
  },
  created() {
    this.$auth.getTokenSilently().then((token) => {
      this.accessToken = token;
    });
    this.fetchContents();
    this.pushHistory();
  },
  methods: {
    pushHistory() {
      this.$authAxios.post("/api/history", { id: this.id });
    },
    fetchContents() {
      this.loadingContents = true;

      this.$authAxios
        .get(`/api/contents/${this.id}?video`)
        .then((response) => {
          this.contents = response.data;

          if (response.data.my_evaluation) {
            this.evaluated = true;
          } else {
            this.contents.my_evaluation = 3; //default
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingContents = false;
          if (!this.video_inited) {
            if (this.contents.video.status !== "FINISH") {
              this.$refs.video.init(this.contents.video.current_time);
            } else {
              this.$refs.video.init(0);
            }
            this.video_inited = true;
          }
        });
    },
    evaluate() {
      this.$authAxios
        .post(`/api/contents/${this.contents.id}/evaluate`, {
          evaluation: this.contents.my_evaluation,
        })
        .then(() => {
          this.fetchContents();
        });
    },
  },
};
</script>
