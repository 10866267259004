<template>
  <article class="c_single">
    <h1 class="c_single_ttl">{{ contents.name }}</h1>
    <!-- 一覧要文章 -->
    <!--
          <p class="c_single_lead">一覧用文章がはいります。一覧用文章がはいります。一覧用文章がはいります。一覧用文章がはいります。一覧用文章がはいります。一覧用文章がはいります。一覧用文章がはいります。一覧用文章がはいります。</p>
    -->
    <div class="c_single_movie">
      <div class="pt-3" :style="{ width: '100%', height: playerHeight + 'px' }">
        <video-player
          class="px-6"
          el="player"
          ref="player"
          :options="videoOptions"
          @play="play"
          @pause="pause"
          @ended="ended"
        ></video-player>
      </div>
    </div>
    <div class="c_single_cnt">
      <!--
      <dl class="c_single_count">
        <dt>視聴回数</dt>
        <dd>○○回</dd>
      </dl>
      -->
      <p class="c_single_disc">{{ contents.description }}</p>

      <div
        class="c_tool_item_eval c_tool_item_eval_count mt-1"
        v-if="contents.from_field"
      >
        <div>公開日:</div>
        <time class="mr-1">{{ contents.from_field | formatDateHyphen }}</time>
        <div class="c_tool_item_eval" v-if="!contents.to_indefinite">
          <div>期限:</div>
          <time class="mr-1">{{ contents.to | formatDateHyphen }}</time>
        </div>
      </div>

      <ul class="c_single_item_tags">
        <li v-for="t in contents.tags" :key="t.id">
          <a @click.stop="searchTag(t.text)">{{ t.text }}</a>
        </li>
      </ul>
    </div>
    <div v-if="video.has_attachment || contents.video_attach">
      <div class="c_single_dl" v-if="loading">
        <div class="c_btn -gray c_single_dl_btn">
          <span class="icon-download"></span> ダウンロード中
          <div :class="{ c_load: loading }"></div>
        </div>
      </div>
      <div class="c_single_dl" v-else>
        <a class="c_btn c_single_dl_btn" @click="getAttachment">
          <span class="icon-download"></span> 補助資料ダウンロード
        </a>
      </div>
    </div>
    <!--
          <dl class="c_sigle_contact">
            <dt>お問い合わせ先</dt>
            <dd>JC3センター事務局</dd>
            <dd>メッセージアカウント：000000000</dd>
            <dd><a href="mailto:guide@jc3.or.jp">guide@jc3.or.jp</a></dd>
          </dl>
    -->
  </article>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer";
import { jc3VideoDomain, jc3CloudFrontDomain } from "@/../config.json";
//import axios from "axios";

export default {
  components: {
    VideoPlayer,
  },
  props: {
    contents: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    accessToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      video: {},
      windowWidth: window.innerWidth,
      timer: "",
      first: true,
      loading: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.calculateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateWindowWidth);
  },
  computed: {
    videoOptions() {
      if (this.contents.video_type === 1) {
        return {
          source: `https://${jc3VideoDomain}/v/${this.contents.video_uuid}/hls/video.m3u8?t=${this.accessToken}`,
          poster: `https://${jc3VideoDomain}/v/${this.contents.video_uuid}/thumb`,
          width: "100%",
          height: "100%",
          autoplay: false,
          watermark: {},
          hlsjsConfig: {},
        };
      } else {
        return {
          source: `https://${jc3CloudFrontDomain}/${this.contents.video_uuid}`,
          poster: `https://${jc3CloudFrontDomain}/${this.contents.video_thumb}`,
          width: "100%",
          height: "100%",
          autoplay: false,
          watermark: {},
          hlsjsConfig: {
            xhrSetup: function(xhr) {
              // iPhoneでは動かない
              xhr.withCredentials = true;
            },
          },
        };
      }
    },
    playerHeight: function() {
      return (this.playerWidth * 9) / 16;
    },
    playerWidth: function() {
      if (this.windowWidth > 1280) {
        return 1280;
      } else {
        return this.windowWidth;
      }
    },
    attachmentURL: function() {
      if (this.contents.video_type === 1) {
        return `https://${jc3VideoDomain}/v/${this.contents.video_uuid}/attachment`;
      } else {
        return `https://${jc3CloudFrontDomain}/${this.contents.video_attach}`;
      }
    },
  },
  methods: {
    init(seektime) {
      this.$refs.player.init(seektime);
      if (this.contents.video_type === 1) {
        this.fetchVideo();
      }
    },
    fetchVideo() {
      this.$authAxios
        .get(`https://${jc3VideoDomain}/v/${this.contents.video_uuid}`)
        .then((response) => {
          this.video = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFileNameFromContentDisposition(disposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        const fileName = matches[1].replace(/['"]/g, "");
        return decodeURI(fileName); // 日本語対応
      } else {
        return null;
      }
    },
    responseFileDownload(response, filename) {
      const url = URL.createObjectURL(new Blob([response.data]), {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    },
    getAttachment() {
      if (this.contents.video_type === 1) {
        this.loading = true;
        this.$authAxios
          .get(this.attachmentURL, {
            responseType: "blob",
          })
          .then((response) => {
            const filename = this.getFileNameFromContentDisposition(
              response.headers["content-disposition"]
            );
            this.responseFileDownload(response, filename);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        window.open(this.attachmentURL);
        //axios
        //  .get(this.attachmentURL, {
        //    responseType: "blob",
        //    withCredentials: true,
        //  })
        //  .then((response) => {
        //    const url = response.config.url;
        //    const filename = url.split("/").pop();
        //    this.responseFileDownload(response, filename);
        //  })
        //  .catch((error) => {
        //    console.log(error);
        //  })
        //  .finally(() => {
        //    this.loading = false;
        //  });
      }
    },
    searchTag(tag) {
      this.$router.push({ name: "Search", query: { tag: tag } });
    },
    calculateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    // ログ関連
    savelog(status) {
      if (this.$refs.player.player.isPlaying() || status === "FINISH") {
        this.$authAxios.post("/api/log/video", {
          id: this.contents.id,
          currentTime: this.$refs.player.player.getCurrentTime(),
          duration: this.$refs.player.player.getDuration(),
          status: status,
        });
      }
    },
    play() {
      if (this.first) {
        this.savelog("START");
        this.first = false;
      }
      clearInterval(this.timer);
      this.timer = setInterval(this.savelog.bind(this, "PLAYING"), 5000);
    },
    pause() {
      clearInterval(this.timer);
    },
    ended() {
      this.savelog("FINISH");
    },
  },
};
</script>
